.experience{
	min-height: calc(100vh - 75px - 140px);
	background-color: #3e497a;
	position: relative;
}
.experience h1{
	margin: 0;
	margin-bottom: 25px;
	text-align: center;
	padding-top: 20px;
}

.experience::before{
	content: "";
	background-image: url(../img/computer.jpg);
	background-repeat: no-repeat;
	opacity: 0.15;
	width: 100%;
	height: 100%;
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}
@media only screen and (min-width: 1170px){
	.vertical-timeline-element-date{
		color: white;
		font-size: 1.3rem!important;
	}
}

@media only screen and (max-width: 600px){
	.experience h1{
		font-size: 50px;
		margin-bottom: 25px;
	}
}