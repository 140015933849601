.navbar {
	width: 100%;
	height: 75px;
	background: dimgrey;
	position: relative;
	z-index: 11;
}

.links {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: white;
  }

.navbar a {
	color: white;
	text-decoration: none;
	margin: 20px;
	font-size: 20px;
	font-weight: 600;
  }

  .toggleButton {
	width: 100%;
	height: 75px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
  }

  .toggleButton svg {
	font-size: 50px;
  }
  .toggleButton button {
	margin-right: 20px;
	background-color: transparent;
	border: none;
	color: white;
	cursor: pointer;
  }
  #open {
	height: 100vh;
  }
  
  #open .links {
	flex-direction: column;
	justify-content: flex-start;
	margin-top: 100px;
  }
  
  #open a {
	width: 100%;
	text-align: center;
  }
  
  @media only screen and (max-width: 800px) {
	.toggleButton {
	  display: flex;
	}
	#close a {
	  display: none;
	}
  }
  
  @media only screen and (min-width: 800px) {
	.toggleButton {
	  display: none;
	}
  
	.hiddenLinks {
	  display: none;
	}
  
	#open {
	  height: 100px;
	}
  
	#open .links {
	  flex-direction: row;
	}
  }