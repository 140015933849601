.footer {
	width: 100%;
	height: 140px;
	background: black;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* padding-top: 10px; */
	/* margin-top: 100px; */
	position: relative;
	
  }

  .socialMedia svg {
	color: white;
	margin: 10px;
	font-size: 40px;
	cursor: pointer;
  }

  .footer p {
	color: white;
  }

  @media only screen and (max-width: 600px) {
	.footer svg {
	  font-size: 50px;
	}
  }