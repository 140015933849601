.homepage {
	width: 100%;
	align-items: center;
	font-family: "Arial", sans-serif;
	color: #3e497a;
	background-color: black;
}

hr {
	height: 2px;
	position: absolute;
	background-color: #e9d35b;
	bottom: 0;
	width: 60%;
	left: 50%;
	right: 50%;
	transform: translate(-50%, -50%);
}

.about {
	width: 100%;
	min-height: calc(100vh - 80px);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #f0f0f0;
	position: relative;
}

.navbar a.logo-homelink{
	margin: 0;
	display: block !important;
}

.logo{
	height: 75px;
    top: 0;
    left: 0px;
    position: absolute;
    width: 200px;
}
@media only screen and (min-width: 1000px) {
	.logo{
		left: 90px;
	}
}
.imgLogo{
	background-image: url(../img/logo.png);
	background-size: contain;
	background-repeat: no-repeat;
	height: 100%;
	width: 100%;
}

.homepage::before{
	background-image: url(../img/computer.jpg);
	background-repeat: no-repeat;
	opacity: 0.15;
	width: 100%;
	height: 100%;
	content: "";
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}
.about .imgProfil{
	background: url(../img/moi.jpg);
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    margin: 20px auto 0;
	position: relative;
}
.title {
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	font-size: 50px;
	color: #e9d35b;
	position: relative;
	text-transform: uppercase;
}
.about .content {
    display: flex;
    align-items: center;
	flex-direction: row;
}

.about .resume {
	font-size: 20px;
	position: relative;
}

.readMorelink a{
	display: inline-block;
	margin-top: 10px;
	background-color: #fa6400;
	color: #fff;
	padding: 15px;
	border-radius: 3px;
	text-decoration: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 2px;
}
.readMorelink:hover a{
	background-color: #f5873ded;
}
@media only screen and (max-width: 840px) {
	.about h2 {
		font-size: 40px;
	}
}
@media only screen and (max-width: 1000px) {
	.about .content {
		flex-direction: column;
		text-align: center;
		padding: 20px;
	}
}
@media only screen and (max-width: 600px) {
	.about h2 {
		font-size: 30px;
	}
	.about .resume {
	  margin-top: 10px;
	  font-size: 20px;
	}
}
@media only screen and (min-width: 600px) {
	.about .content{
		padding: 0px 100px;;
	}
	.presentation {
		flex-basis: 60%;
	}
}


