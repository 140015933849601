
.projects {
	min-height: calc(100vh - 75px - 140px);
	background-color: #696969;
	position: relative;
	padding: 40px 0px;
  }
.projects .title{
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 50px;
    color: #e9d35b;
    position: relative;
    margin: 0;
    text-align: center;
}
.projectsIntro{
	font-size: 20px;
	position: relative;
	color: white;
	font-family: "Arial", sans-serif;
}
.projects::before{
	content: "";
	background-image: url(../img/fond_ecran.jpg);
	background-repeat: no-repeat;
	opacity: 0.15;
	width: 100%;
	height: 100%;
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

.projects .projectTitle {
text-align: center;
color: antiquewhite;
font-size: 22px;
margin: 5px;
font-weight: bold;
}
  
.projectList {
	position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
  
/* MENU ITEM STYLING */
  
.projectItem {
	border-radius: 15px;
	width: 300px;
	height: 300px;
	margin: 20px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
	text-align: center;
	background: black;
	transition: all 400ms ease;
}

.projectItem a {
    text-decoration: none;
}

span.ItemStack {
  background: black ;
	color: #e9d35b;
  border-radius: 999px;
  padding: 2px 5px;
}
.stacks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.projectItem:hover {
	box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
	transform: translateY(-3%);
	cursor: pointer;
}
  
.projectItem .bgImage {
	border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
}
  
.projectItem h1 {
	font-size: 25px;
}
  
@media only screen and (max-width: 1300px) {
	.projectList {
	  grid-template-columns: 1fr 1fr;
	}
}
  
@media only screen and (max-width: 800px) {
	.projectList {
	  grid-template-columns: 1fr;
	}
}
  
@media only screen and (max-width: 800px) {
	.projectList {
	  padding: 20px;
	  text-align: center;
	}
	.projectItem {
	  width: 300px;
	  height: 300px;
	}
}
@media only screen and (max-width: 600px){
	.projects .title{
		font-size: 35px;
	}	
}
@media only screen and (min-width: 600px){
	.projects {
		padding: 50px 100px;
	}
}