body{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Arial", sans-serif;
}

.App{
	width: 100%;
	height: auto;
	background: #f0f0f0;
}

.min{
	height: 115px;
}