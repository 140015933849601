.skills{
	min-height: calc(100vh - 75px - 140px);
	background-color: black;
	position: relative;
}
.skills::before{
	content: "";
	background-image: url(../img/computer.jpg);
	background-repeat: no-repeat;
	opacity: 0.15;
	width: 100%;
	height: 100%;
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}
.contentItem{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
}

.skills h1 {
	text-align: center;
	margin: 0;
	position: relative;
}
.skills .serviceIntro{
	font-size: 20px;
	color: white;
	font-family: "Arial", sans-serif;
}

.itemCard{
	margin: 20px;
	border-radius: 15px;
	overflow: hidden;
	box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
	height: 290px;
	width: 200px;
	cursor: pointer;
	transition: all 400ms ease;
}
.itemCard h2{
	margin: 0;
	padding-top: 20px ;
	margin-bottom: 10px;
}

.itemCard:hover{
	box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
	transform: translateY(-3%);
}

.itemIcon svg {
    width: auto;
    height: 100%;
	color: white;
}

.itemCard .itemIcon{
	height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.itemCard .itemContent{
	padding: 0 20px;
	text-align: center;
	background-color: white;
	height: 60%;
}

@media only screen and (max-width: 600px){
	.skills {
		height: 100%;
		padding: 50px 35px;
		text-align: center;
	}
	.skills h1{
		font-size: 35px;
	}
	.contentItem {
		margin-top: 25px;
	}
}

@media only screen and (min-width: 600px){
	.skills {
		padding: 50px 100px;
	}
}
