.contact{
	min-height: calc(100vh - 75px - 140px);
	background-color: black;
	position: relative;
  text-align: center;
}
#contact-form{
	position: relative;
  margin-top: 40px;
}
.contact h1{
	margin: 0;
  text-align: center;
  padding-top: 20px;
}
.contactIntro{
  font-size: 20px;
	position: relative;
	color: white;
	font-family: "Arial", sans-serif;
}

.contact::before{
	content: "";
	background-image: url(../img/computer.jpg);
	background-repeat: no-repeat;
	opacity: 0.15;
	width: 100%;
	height: 100%;
	background-size: cover;
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}
label{
	display: inline-block;
	width: 100%;
	text-transform: uppercase;
	color: white;
	font-weight: bold;
	font-size: 18px;
}

input[type=text], input[type=email], textarea{
	width: 80%;
	padding: 12px 20px;
	margin: 8px 0;
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
}
form#contact-form {
    text-align: center;
}
textarea.form-control {
  min-height: 150px;
}

/* CSS */
.btn-form {
  align-items: center;
  background-clip: padding-box;
  background-color: #fa6400;
  border: 1px solid transparent;
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  margin-top: 20px;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.btn-form:hover, .btn-form:focus {
  background-color: #fb8332;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.btn-form:hover {
  transform: translateY(-1px);
}

.btn-form:active {
  background-color: #c85000;
  box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
  transform: translateY(0);
}

.alert {
    background: green;
    text-align: center;
    padding: 20px;
    margin: 10px auto 0px;
    width: 50%;
    color: white;
    font-size: 15px;
}

@media only screen and (max-width: 600px){
	.contact {
		padding: 0 20px;
	}
}

@media only screen and (min-width: 600px){
	.contact {
		padding: 50px 100px;
	}
}